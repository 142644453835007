import React from "react"
import Swiper from "react-id-swiper"
import screen00 from "../../img/elsaM_screen00.jpg"
import screen01 from "../../img/elsaM_screen01.jpg"
import screen02 from "../../img/elsaM_screen02.jpg"
import screen03 from "../../img/elsaM_screen03.jpg"
import screen04 from "../../img/elsaM_screen04.jpg"
import screen05 from "../../img/elsaM_screen05.jpg"
import "../../styles/swiper.css"

const ElsaMCoverflowEffect = () => {
  const params = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "3",
    initialSlide: 1,
    slideToClickedSlide: true,
    spaceBetween: -100,
    coverflowEffect: {
      rotate: 60,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  }
  return (
    <Swiper {...params}>
      <div className="swiper-slide">
        <img src={screen00} alt="" />
      </div>
      <div className="swiper-slide">
        <img src={screen01} alt="" />
      </div>
      <div className="swiper-slide">
        <img src={screen02} alt="" />
      </div>
      <div className="swiper-slide">
        <img src={screen03} alt="" />
      </div>
      <div className="swiper-slide">
        <img src={screen04} alt="" />
      </div>
      <div className="swiper-slide">
        <img src={screen05} alt="" />
      </div>
    </Swiper>
  )
}
export default ElsaMCoverflowEffect
